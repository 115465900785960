@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
blockquote,
pre,
address,
ul,
ol,
li,
dl,
dt,
dd,
caption,
form,
fieldset,
legend,
input {
  margin: 0;
  padding: 0;
  letter-spacing: -0.1px;
}

ul,
ol {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

a {
  text-decoration: none;
}

caption,
th {
  text-align: left;
}

img,
fieldset,
abbr,
acronym {
  border: 0;
}

img {
  -ms-interpolation-mode: bicubic;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

button {
  all: unset;
}


html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #eef0f1;
}

body {
  color: black;
  font-family: 'Inter';
}

a {
  color: black;
}

a {
  pointer-events: all;
  color: black;
  text-decoration: none;
}

svg {
  fill: black;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

canvas {
  opacity: 1;
  touch-action: none;
  animation: fade-in 5s ease 1s forwards;
}

.rootWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.Cursor {
  top: 0;
  left: 0;
  position: fixed;
  border-radius: 50%;
  /* pointer-events: none; */
}


.ContentWrap {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 15px;
  z-index: 1;
  opacity: 1;
}


.Logo {
  position: absolute;
  width: 45px;
  height: auto;
  fill: #000;
  transition: 0.2s;
}


.LogoText {
  position: absolute;
  padding: 6px 0px 3px 54px;
}

.LogoText>h6 {
  font-size: 18px;
  transition: 0.2s;
}

.LogoText>p {
  font-size: 10px;
  transition: 0.2s;
}

/* .LogoBtn:hover svg {
  fill: #fff;
  transition: 0.2s;
}

.LogoBtn:hover h6,.LogoBtn:hover p {
  color:#fff;
  transition: 0.2s;
} */


.CloseBtn {
  width: 45px;
  height: 45px;
}

/* nav */

.HamburgerBtnWrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  top: 10px;
  right: 10px;
  z-index: 11;
}


.MenuWrap {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  /* background-color:#f8f5f1; */
  background-color: #f0eee2;
  transition: 0.2s;
}

.MenuWrap>a {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 7vw;
  font-weight: 900;
  margin: 6vh 0;
}

.MenuWrap>a:hover {
  color: #fff;
  transition: 0.2s;
}

.CurrentPage {
  color:#e5e2d0;
  pointer-events: none;
}

@media only screen and (max-width: 540px) {

  .MenuWrap>a {
    font-size: 36px;
  }
}


.Copyright {
  position: absolute;
  font-size: 12px;
  left: 15px;
  bottom: 18px;
}

.Email {
  position: absolute;
  right: 15px;
  bottom: 9px;
}

.EmaiIcon {
  cursor: pointer;
  fill: #000;
}

.EmaiIcon:hover {
  fill: #fff;
  transition: 0.2s;
}

.LeftBtn {
  position: absolute;
  top: 50%;
  left: 30px;
  z-index: 2;
  transform: translate(0%, -50%);
}

.RightBtn {
  position: absolute;
  top: 50%;
  right: 30px;
  z-index: 2;
  transform: translate(0%, -50%);
}

.LeftBtn>svg {
  cursor: pointer;
  fill: #000;
}

.LeftBtn>svg:hover {
  cursor: pointer;
  fill: #fff;
  transition: 0.2s;
}

.RightBtn>svg {
  cursor: pointer;
  fill: #000;
}

.RightBtn>svg:hover {
  cursor: pointer;
  fill: #fff;
  transition: 0.2s;
}

.LinkBtn {
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  width: 100%;
  max-width: 210px;
  height: 51px;
  left: 50%;
  bottom: 60px;
  transform: translate(-50%, 0%);
  text-align: center;
  vertical-align: bottom;
  background-color: #fff;
  overflow: hidden;
}


.LinkBtn>p {
  position: absolute;
  color: #000;
  z-index: 1;
  transition: 0.3s;
}

.LinkBtn>span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0px;
  height: 0px;
  border-radius: 50%;
  background-color: #fff;
  z-index: 0;
  transition: 0.3s;
}

.LinkBtn:hover span {
  width: 220px;
  height: 210px;
  background-color: #000;
  transition: 0.3s;
}

.LinkBtn:hover p {
  color: #fff;
  transition: 0.3s;
}


.Esty {
  position: absolute;
  width: 30px;
  height: auto;
  right: 18px;
  bottom: 12px;
}

.Esty>svg {
  cursor: pointer;
  fill: #000;
}

.Esty>svg:hover {
  cursor: pointer;
  /* fill:#fff; */
  transition: 0.2s;
}

.DarkBg {
  animation: DarkbackgroundChange 1.4s ease-out forwards;
}

@keyframes DarkbackgroundChange {
  25% {
    background-color: #f0f0f0;
  }

  100% {
    background-color: #25231a;
  }
}

.LightBg {
  animation: LightbackgroundChange 0.8s ease-out forwards;
}

@keyframes LightbackgroundChange {
  0% {
    background-color: #25231a;
  }

  100% {
    background-color: #f0f0f0;
  }
}

.DakrOn>a>.Logo {
  fill: #fff;
  transition: 0.4s;
}

.DakrOn>a:hover .Logo {
  fill: #000;
  transition: 0.4s;
}

.DakrOn>a:hover h6,
.DakrOn>a:hover p {
  color: #000 !important;
  transition: 0.4s;
}

.DakrOn>a>.LogoText>h6,
.DakrOn>a>.LogoText>p {
  color: #fff;
  transition: 0.4s;
}

.DakrOn>.Copyright {
  color: #fff;
  transition: 0.4s;
}

.DakrOn>.Esty>svg,
.DakrOn>.Email>svg {
  fill: #fff;
  transition: 0.4s;
}

.DakrOn>.Esty>svg:hover,
.DakrOn>.Email>svg:hover {
  fill: #000;
  transition: 0.4s;
}

.AboutBg {
  animation: AboutbackgroundChange 1.4s forwards;

}

@keyframes AboutbackgroundChange {
  25% {
    background-color: #f0f0f0;
  }

  100% {
    background-color: #2e2c2e;
  }
}



.GuideWrap {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(23, 23, 23, 1);
  z-index: 11;
}

.LogoWrap {
  padding: 15px;
  color: #fff;
  text-align: center;
  height: 380px;
}

.LoaderWrap {
  position: absolute;
  width: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 380px;
}

/* HTML: <div class="loader"></div> */
.Loader {
  position: absolute;
  bottom: 0;
  width: 60px;
  aspect-ratio: 6;
  --_g: no-repeat radial-gradient(circle closest-side, #fff 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: l7 1s infinite linear;
}

@keyframes l7 {
  33% {
    background-size: calc(100%/3) 0%, calc(100%/3) 100%, calc(100%/3) 100%
  }

  50% {
    background-size: calc(100%/3) 100%, calc(100%/3) 0%, calc(100%/3) 100%
  }

  66% {
    background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0%
  }
}

.LogoWrap>.GuideLogo {
  fill: #fff;
  width: 120px;
  height: auto;
}

.LogoWrap>h5 {
  font-size: 36px;
  font-weight: 900;
}

.LogoWrap>h6 {
  font-size: 17px;
  font-weight: 700;
  padding: 2px;
}


.ExploreBtn {
  position: relative;
  cursor: pointer;
  color: #000;
  font-size: 15px;
  font-weight: 700;
  width: 210px;
  height: 51px;
  background-color: #fff;
  transition: 0.2s;
  overflow: hidden;

}

.ExploreBtn>p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  z-index: 1;
  transition: 0.3s;
}

.ExploreBtn>span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0px;
  height: 0px;
  border-radius: 50%;
  background-color: #fff;
  z-index: 0;
  transition: 0.3s;
}

.ExploreBtn:hover span {
  width: 220px;
  height: 210px;
  background-color: #000;
  transition: 0.3s;
}

.ExploreBtn:hover p {
  color: #fff;
  transition: 0.3s;
}

.btnOff {
  opacity: 0;
  transition: 0.4s;
  pointer-events: none;
}

.btnOn {
  opacity: 1;
  transition: 0.4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.GuideAnimation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 9px;
}

.GuideAnimation>p {
  font-size: 12px;
  padding: 12px;
  font-weight: 700;
  color: #cbcbcb;
}

.GuideAnimation>span {
  font-size: 13px;
  padding: 12px;
  font-weight: 700;
  color: #fff;
}

.Draggable {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 50%;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  animation: moveAround 3.6s infinite ease-in-out;
}

.Draggable>.cycle {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #fff;
  z-index: 100;
  animation: DotAni 1.8s infinite ease-in-out;
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
    left: 200%;
  }

  50% {
    background-position: 160px 0%;
    left: -100%;
  }

  100% {
    background-position: 0% 0%;
    left: 200%;
  }
}



@keyframes moveAround {
  0% {
    transform: translateX(-80px);
    border: 1px solid rgba(255, 255, 255, 0.7);
  }

  10% {
    transform: translateX(-80px);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  40% {
    transform: translateX(80px);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  50% {
    transform: translateX(80px);
    border: 1px solid rgba(255, 255, 255, 0.7);
  }

  60% {
    transform: translateX(80px);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  90% {
    transform: translateX(-80px);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  100% {
    transform: translateX(-80px);
    border: 1px solid rgba(255, 255, 255, 0.7);
  }
}

@keyframes DotAni {
  0% {
    opacity: 1;
  }

  35% {
    opacity: 0.2;
  }

  65% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.Wait {
  opacity: 1;
}

.Start {
  opacity: 1;
  animation: FirstStartHide 1.1s ease-in-out forwards;
}

@keyframes FirstStartHide {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  30% {
    opacity: 1;
    transform: scale(1);
  }

  99% {
    opacity: 0;
    transform: scale(2);
  }

  100% {
    z-index: -1;
  }
}

.NotFound{
  position: relative;
  display: flex;
  flex-direction: column;
  width:100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgba(23, 23, 23, 1);
}

.NotFoundWrap{
  padding: 15px;
  text-align: center;
  color:#fff;
}

.NotFoundWrap > svg{
  fill:#fff;
  width:60px;
  height:auto;
  padding-bottom: 21px;
}

.NotFoundWrap > h1{
  font-size:24px;
  padding: 3px;
}

.NotFoundWrap > h6{
  font-size:12px;
  padding-bottom: 30px;
}
